import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import moment from "moment-timezone";
import CommonMixin from "@/core/plugins/common-mixin";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
export default {
  mixins: [CommonMixin],
  name: "warranty-detail",
  props: {
    detail: {
      type: Object,
      required: true,
      default: function _default() {
        return new Object();
      }
    }
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        this.data = param;
        this.setWarrantyDuration();
      }
    }
  },
  data: function data() {
    return {
      data: new Object(),
      durationString: null
    };
  },
  methods: {
    setWarrantyDuration: function setWarrantyDuration() {
      var _this = this;

      var startDate = moment(_this.detail.warranty_start_date);
      var endDate = moment(_this.detail.warranty_end_date);
      var diffInDays = endDate.diff(startDate, "days");
      var result = [];
      var totalDuration = moment.duration(diffInDays, "days");
      var years = totalDuration.get("years");

      if (years > 0) {
        if (years == 1) {
          result.push(years + " Year");
        } else {
          result.push(years + " Years");
        }
      }

      var months = totalDuration.get("months");

      if (months > 0) {
        if (months == 1) {
          result.push(months + " Month");
        } else {
          result.push(months + " Months");
        }
      }

      var days = totalDuration.get("days");

      if (days > 0) {
        if (days == 1) {
          result.push(days + " Day");
        } else {
          result.push(days + " Days");
        }
      }

      var hours = totalDuration.get("hours");

      if (hours > 0) {
        if (hours == 1) {
          result.push(hours + " Hour");
        } else {
          result.push(hours + " Hours");
        }
      }

      var minutes = totalDuration.get("minutes");

      if (minutes > 0) {
        if (minutes == 1) {
          result.push(minutes + " Minute");
        } else {
          result.push(minutes + " Minutes");
        }
      }

      _this.durationString = result.join(" ");
    }
  }
};