import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import moment from "moment-timezone";
import { QUERY, DELETE, GET } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import WarrantyDetail from "@/view/pages/warranty/Warranty-Detail.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
export default {
  mixins: [CommonMixin],
  name: "warranty-product-list",
  data: function data() {
    return {
      dates: [],
      warranty: [],
      warrantyLoading: false,
      dataLoading: true,
      detailDialog: false,
      voidWarrantyDialog: false,
      warrantyDetail: new Object(),
      momentObject: moment,
      customerList: [],
      jobList: [],
      filter: {
        search: null,
        customer: 0,
        job: 0
      },
      timeout: null,
      timeoutLimit: 500
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: function _default() {
        return new Object();
      }
    },
    type: {
      type: String,
      required: true,
      default: "customer"
    }
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler: function handler() {
        this.getWarranty();
      }
    }
  },
  components: {
    Barcode: Barcode,
    Dialog: Dialog,
    ListingTable: ListingTable,
    WarrantyDetail: WarrantyDetail
  },
  methods: {
    clearFilter: function clearFilter(field) {
      var _this = this;

      switch (field) {
        case "search":
          _this.filter.search = null;
          break;

        case "dates":
          _this.dates = [];
          break;
      }

      _this.getWarranty();
    },
    voidWarranty: function voidWarranty() {
      var _this = this;

      _this.warrantyLoading = true;

      _this.$store.dispatch(DELETE, {
        url: "warranty/" + _this.warrantyDetail.id
      }).then(function () {
        _this.voidWarrantyDialog = false;
        _this.detailDialog = false;
        _this.warrantyDetail = new Object();
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.warrantyLoading = false;

        _this.getWarranty();
      });
    },
    getJobs: function getJobs() {
      var _this = this;

      _this.$store.dispatch(GET, {
        url: "job/list-all"
      }).then(function (_ref) {
        var data = _ref.data;
        _this.jobList = data;
      }).catch(function (error) {
        _this.logError(error);
      });
    },
    detailDialogConfirm: function detailDialogConfirm(row) {
      this.warrantyDetail = row;
      this.detailDialog = true;
    },
    getWarranty: function getWarranty() {
      var _this = this;

      var filter = {
        search: _this.filter.search || undefined,
        daterange: _this.dates || undefined
      };

      if (!_this.type || !_this.detail.id) {
        return false;
      }

      if (_this.type == "customer") {
        filter.customer = _this.lodash.toSafeInteger(_this.detail.id);
      }

      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit("update:error", InitializeError("End Date must be after Start Date."));
          return false;
        }
      } else {
        filter.daterange = undefined;
      }

      clearTimeout(_this.timeout);
      _this.warranty = [];
      _this.dataLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store.dispatch(QUERY, {
          url: "warranty",
          data: {
            filter: filter
          }
        }).then(function (_ref2) {
          var data = _ref2.data;
          _this.warranty = data.rows;
        }).catch(function (error) {
          _this.logError(error);
        }).finally(function () {
          _this.dataLoading = false;
        });
      }, _this.timeoutLimit);
    }
  },
  mounted: function mounted() {
    this.getWarranty();
    this.getJobs();
  },
  beforeMount: function beforeMount() {}
};