import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import { GET } from "@/core/services/store/request.module";
export default {
  data: function data() {
    return {
      isDuplicateMode: false,
      transaction_type: null,
      transaction_contact_person_dialog: false,
      transaction_customer: 0,
      transaction_contact_person: 0,
      transaction_property: 0
    };
  },
  methods: {
    getProperty: function getProperty() {
      var _this = this;

      return new Promise(function (resolve, reject) {
        try {
          _this.$store.dispatch(GET, {
            url: "property/" + _this.property
          }).then(function (response) {
            resolve(response.data);
          }).catch(function (error) {
            reject(error);
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    resetAll: function resetAll() {
      this.transaction_customer = 0;
      this.transaction_contact_person = 0;
      this.transaction_property = 0;
      this.closeDialog();
    },
    closeDialog: function closeDialog() {
      this.transaction_contact_person_dialog = false;
    },
    selectCustomerPerson: function selectCustomerPerson(person) {
      this.transaction_contact_person = this.lodash.toSafeInteger(person);
      this.transaction_contact_person_dialog = false;
      this.checkJob();
    },
    checkJob: function checkJob() {
      var _this = this;

      var customer = _this.lodash.toSafeInteger(_this.transaction_customer);

      var contact_person = _this.lodash.toSafeInteger(_this.transaction_contact_person);

      var property = _this.lodash.toSafeInteger(_this.transaction_property);

      if (customer > 0 && contact_person > 0 && property > 0) {
        _this.$router.push(_this.getDefaultRoute(_this.transaction_type + ".create", {
          query: {
            customer: customer,
            contact_person: contact_person,
            property: property
          }
        }));
      }
    },
    createTransaction: function createTransaction(property, param) {
      var _this = this;

      _this.transaction_customer = _this.lodash.toSafeInteger(property.customer.id);
      _this.transaction_property = _this.lodash.toSafeInteger(property.id);
      _this.transaction_type = _this.lodash.toString(param);

      if (property.contact_person_count > 1) {
        _this.transaction_contact_person_dialog = true;
      }

      if (property.contact_person_count == 1) {
        _this.transaction_contact_person = property.contact_person.id;
      }

      _this.checkJob();
    }
  }
};