import _objectSpread from "E:/AirVenture/frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PropertyMixin from "@/core/plugins/property-mixin";
import Barcode from "@/view/pages/partials/Barcode.vue";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import CustomerPersonDialog from "@/view/pages/partials/Select-Customer-Person.vue";
import Tickets from "@/view/pages/partials/Detail/Tickets";
import Quotations from "@/view/pages/partials/Detail/Quotation";
import Invoices from "@/view/pages/partials/Detail/Invoice";
import Products from "@/view/pages/partials/Detail/Products";
import Warranty from "@/view/pages/partials/Detail/Warranty";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import { GET } from "@/core/services/store/request.module";
export default {
  mixins: [CommonMixin, ValidationMixin, PropertyMixin],
  name: "property-detail",
  title: "Property",
  data: function data() {
    return {
      property: 0,
      customer: 0,
      //propertyTab: null,
      pageLoading: true,
      historyLoading: true,
      deleteDialog: false,
      propertyArr: {},
      history: [],
      tabs: [{
        title: "Overview",
        icon: "mdi-phone",
        key: "property",
        disabled: false
      }, {
        title: "Property History",
        icon: "mdi-phone",
        key: "property-history",
        disabled: false
      },
      /*{
        title: "Jobs",
        icon: "mdi-credit-card",
        key: "job",
        disabled: false,
      },*/

      /*{
        title: "Quotations",
        icon: "mdi-account-multiple",
        key: "quotation",
        disabled: false
      },*/
      {
        title: "Invoices",
        icon: "mdi-account-multiple",
        key: "invoice",
        disabled: false
      }
      /*{
        title: "Products",
        icon: "mdi-credit-card",
        key: "product",
        disabled: false
      },
      {
        title: "Warranty",
        icon: "mdi-credit-card",
        key: "warranty",
        disabled: false
      },*/

      /*{
        title: "History",
        icon: "mdi-credit-card",
        key: "history",
        disabled: false,
      },*/
      ],
      createTransactions: [{
        title: "Visit",
        action: "job"
      },
      /*{
        title: "Quotation",
        action: "quotation"
      },*/
      {
        title: "Invoice",
        action: "invoice"
      }]
    };
  },
  components: {
    Barcode: Barcode,
    ListingTable: ListingTable,
    DetailTemplate: DetailTemplate,
    CustomerPersonDialog: CustomerPersonDialog,
    Tickets: Tickets,
    Quotations: Quotations,
    Products: Products,
    Warranty: Warranty,
    Invoices: Invoices
  },
  methods: {
    deleteProperty: function deleteProperty() {
      var _this = this;

      if (!_this.$refs.propertyDeleteForm.validate()) {
        return false;
      }
    },
    getHistories: function getHistories() {
      var _this = this;

      _this.$store.dispatch(GET, {
        url: "property/".concat(_this.property, "/history")
      }).then(function (_ref) {
        var data = _ref.data;
        _this.history = data;
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.historyLoading = false;
      });
    }
  },
  mounted: function mounted() {
    var _this = this;

    _this.checkTransactionPermission();

    _this.getHistories();

    _this.checkTabPermission();

    _this.getProperty().then(function (response) {
      _this.propertyArr = response;
      _this.customer = response.customer ? _this.lodash.toSafeInteger(response.customer.id) : 0;
      _this.mapCenter = {
        lat: parseFloat(response.latitude),
        lng: parseFloat(response.longitude)
      };

      _this.$store.dispatch(SET_BREADCRUMB, [{
        title: "Property",
        route: "property"
      }, {
        title: "Detail"
      }, {
        title: response.barcode
      }]);
    }).catch(function (error) {
      _this.logError(error);

      _this.$router.go(-1);
    }).finally(function () {
      _this.pageLoading = false;
    });
  },
  created: function created() {
    var _this = this;

    _this.property = _this.$route.params.id;

    if (!_this.property || _this.property <= 0) {
      _this.$router.go(-1);
    }

    if (_this.$route.query && _this.$route.query.tab) {
      _this.propertyTab = _this.$route.query.tab;
    }
  },
  computed: {
    getPageTitle: function getPageTitle() {
      var result = [];

      if (this.propertyArr && this.propertyArr.customer) {
        result.push(this.propertyArr.customer.display_name);
      }
      /*if(this.propertyArr && this.propertyArr.customer && this.propertyArr.customer.default_person){
        result.push(this.propertyArr.customer.default_person.display_name);
      }*/


      if (this.propertyArr && this.propertyArr.barcode) {
        result.push(this.propertyArr.barcode);
      }

      return result.join(" • ");
    },
    propertyTab: {
      set: function set(val) {
        var query = _objectSpread({}, this.$route.query);

        query.tab = val;

        if (val != this.propertyTab) {
          this.$router.push({
            query: query
          });
        }
      },
      get: function get() {
        return this.$route.query.tab || "property";
      }
    },
    countryName: function countryName() {
      var result = "";

      if (this.lodash.isEmpty(this.propertyArr.country) === false) {
        result = this.propertyArr.country.name;
      }

      return result;
    },
    stateName: function stateName() {
      var result = "";

      if (this.lodash.isEmpty(this.propertyArr.state) === false) {
        result = this.propertyArr.state.name;
      }

      return result;
    },
    cityName: function cityName() {
      var result = "";

      if (this.lodash.isEmpty(this.propertyArr.city) === false) {
        result = this.propertyArr.city.name;
      }

      return result;
    },
    getStatus: function getStatus() {
      return this.lodash.isEmpty(this.propertyArr) === false ? this.propertyArr.activated : false;
    },
    getBarcode: function getBarcode() {
      return this.lodash.isEmpty(this.propertyArr) === false ? this.propertyArr.barcode : false;
    }
  }
};